<template>
  <div class="adsItem">
    <ins ref="adIns" class="adsbygoogle" v-bind="getAdConfig()" />
    <div v-if="showDebug" class="ad-msg">
      <span v-if="indexKey >= 0">
        {{ adType }}[{{ indexKey }}]
      </span>
      <span v-else>
        {{ adType }}_{{ adPosition }}
      </span> = {{ getAdConfig() }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    adPosition: {
      type: Number,
      required: true
    },
    adType: {
      type: String,
      required: true
    },
    indexKey: {
      require: false,
      type: [String, Number],
      default () {
        return -1
      }
    },
  },
  computed: {
    ...mapGetters({
      adsense: "adsenseConfig",
      showDebug: "showDebug"
    })
  },
  mounted () {
    this.observeAdStatus()
  },
  methods: {
    observeAdStatus () {
      const adIns = this.$refs.adIns;

      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.attributeName === 'data-ad-status') {
            console.log('Attribute changed:', mutation.target.getAttribute('data-ad-status'));
          }
        });
      });

      observer.observe(adIns, {
        attributes: true,
        attributeFilter: ['data-ad-status'],
      });
    },
    getAdConfig () {
      if (this.indexKey != -1) {
        console.log(`广告位置对应new：${this.adType}[${this.indexKey}]`);
        return this.adsense[this.adType][this.indexKey] || {};
      } else {
        console.log(`广告位置对应：${this.adType}_${this.adPosition}`);
        return this.adsense[`${this.adType}_${this.adPosition}`] || {};
      }
    },
  },
}
</script>